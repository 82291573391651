
export const splitEqualEn = (str,num,lang) => {
  if (str) {
    const strValue = str.replace(/([A-Z])!(A-Z)/g, ' $1')
    let newStr = (`${strValue}`.split(' ') || []).filter(it => !!it)
    if (newStr.length > 3) {   
      if(lang==='en'&& num>=8){
        newStr = newStr[0] + '' + newStr[1] + '\n' + newStr[2] +'...'
      }else{
        newStr = newStr[0] + '\n' + newStr[1] + '\n' + newStr[2] + '...'
        // 三行文本
      } 
    } else {
      newStr = str
    }
    return newStr
  }
}

export const splitEqualCn = (str, num = 4) => {
  if (str) {
    const idx = str.length / num
    const newStrArr = []
    for (let index = 0; index < idx; index++) {
      newStrArr.push(str.slice(index * num, num * (index + 1)))
    }
    let newStr = ''
    if (newStrArr.length > num - 1) {
      newStr = newStrArr[0] + '\n' + newStrArr[1] + '\n' + newStrArr[2] + '...'
    } else {
      newStr = newStrArr.map((it, idx) => `${it}${(idx + 1) === newStrArr.length ? '' : '\n'}`).join('')
    }
    return newStr
  }
}

export const formatterTxt = (params, lang, num = 4) => {
 
  let str = ''
  if (lang === 'cn') {
    str = params.data.name
  } else {
    str = params.data.nameEn || params.data.name
  }
  let value = lang === 'cn' ? splitEqualCn(str, num) : splitEqualEn(str, num,lang)
  if (params.data.proportion) {
    // console.log(params);
    // console.log(params.data.proportion);

    value = (value || '') + ': ' + params.data.proportion 
  }
  return value || ''
}
export const formatterTxtTips = (params, lang) => {
  if (params.data.value) {
    if (lang === 'cn') {
      return params.data.name + ': ' + params.data.proportion
    } else {
      return params.data.nameEn + ': ' + params.data.proportion 
    }
  } else {
    if (lang === 'cn') {
      return params.data.name
    } else {
      return params.data.nameEn
    }
  }
}

export function formatJson (val) {
  const newList = []
  for (const key in val) {
    if (Object.hasOwnProperty.call(val, key)) {
      const element = val[key]
      newList.push({ aaa_id: key, children: element })
    }
  }
  return newList
}

export const formatList = (list, fatherAaa_id, isMax) => {
  // 找最大值
  // 剔除other 找max
  // console.log(`list`, list);
  const maxLists = list.filter(it => it.entname !== '')
  const max = Math.max(...maxLists.map(item =>{
    if (isNaN(item.proportion)) {
      // console.log(`item`, item);
      const end = (item.proportion.toString()).match(/\D+$/g)
      // console.log(`end`, end);
      item.proportion = parseFloat(item.proportion)
      item.proportion = Math.floor(item.proportion * 100) / 100 + end
    } else {
      // console.log(`item.proportion`, item.proportion);
      item.proportion = Math.floor(item.proportion * 100) / 100
      // console.log(`item.`, item.proportion);
    }
   return Number(item.proportion)
  }))
  return list.map((item, i) => {
    // console.log(`item`, item);
    if (isNaN(item.proportion)) {
      // console.log(`item`, item);
      const end = (item.proportion.toString()).match(/\D+$/g)
      // console.log(`end`, end);
      item.proportion = parseFloat(item.proportion)
      item.proportion = Math.floor(item.proportion * 100) / 100 + end
    } else {
      console.log(`item.proportion`, item.proportion);
      item.proportion = Math.floor(item.proportion * 100) / 100
      // console.log(`item.`, item.proportion);
    }
    // console.log(`ismax`, isMax);
   
    // console.log(`max`, max);

    const value = Number(item.proportion)
    // console.log(`value`, value);
    // console.log(`max`, max);
    const blo = value === max && isMax
    const color = blo ? '#F72C5B' : '#ccc'
    // console.log(`item`, item);
    const params = {
      ...item,
      fatherAaa_id,
      name: item.name,
      nameEn: item.nameEn,
      value,
      aaa_id: item.code,
      itemStyle: { color },
      lineStyle: { color },
      isMax: blo,
      // children: item.code ? [] : []
    }
    return params
  })
}
